import React, { useRef, useEffect, useState } from "react";
import Header from "../sections/Header";
import Hero from "../sections/Hero";
import About from "../sections/About";
import Services from "../sections/Services";
import Contact from "../sections/Contact";
import Footer from "../sections/Footer";
import Team from "../sections/Team";
import PortofolioCard from "../portfolio/PortofolioCard";
import Features from "../sections/Features";
import BackToTop from "../ui/BackToTop";

const Home = () => {
  const welcomeRef = useRef(null);
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const projectRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);

  const initialData = []

  const [scrollData, setScrollData] = useState(initialData)

  useEffect(() => {
    return (window.onscroll = (e) => {
      const [
        welcomeDis,
        aboutDis,
        serviceDis,
        projectDis,
        teamDis,
        contactDis,
      ] = [
          welcomeRef.current.getBoundingClientRect().y,
          aboutRef.current.getBoundingClientRect().y,
          serviceRef.current.getBoundingClientRect().y,
          projectRef.current.getBoundingClientRect().y,
          teamRef.current.getBoundingClientRect().y,
          contactRef.current.getBoundingClientRect().y,
        ];

      setScrollData([
        { dis: Math.abs(welcomeDis), element: "welcome" },
        { dis: Math.abs(aboutDis), element: "about" },
        { dis: Math.abs(serviceDis), element: "services" },
        { dis: Math.abs(projectDis), element: "portfolio" },
        { dis: Math.abs(teamDis), element: "team" },
        { dis: Math.abs(contactDis), element: "contact" },
      ].sort((a, b) => a.dis - b.dis));
    });
  }, []);
  return (
    <>
      <Header scroll={scrollData.length ? scrollData[0].element : ""} />
      <Hero reference={welcomeRef} />
      <About reference={aboutRef} />
      <Services reference={serviceRef} />
      <Features />
      <PortofolioCard reference={projectRef} />
      <Team reference={teamRef} />
      <Contact reference={contactRef} />
      <Footer />
      <BackToTop />
    </>
  );
};

export default Home;
