import React from 'react';
import { useState } from "react";
import axios from "axios"
import config from '../../config';
const initialData = {
    name: "",
    email: "",
    subject: "",
    message: "",
};
const validateSate = {
    name: "",
    email: "",
    subject: "",
    message: "",
}
const Contact = ({ reference }) => {
    const [formData, setFormData] = useState(initialData);
    const [validationErrors, setValidationErrors] = useState(validateSate);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = (email) => {
        return emailPattern.test(email);
    };
    const handleSubmit = async (event) => {
        event.preventDefault()
        setSuccessMessage("")
        setErrorMessage("")
        setIsLoading(true)
        const newValidationErrors = {
            name: !formData.name.trim() ? "Name is required" : "",
            email: !formData.email.trim() ? "Email is required" : "",
            subject: !formData.subject.trim() ? "Subject is required" : "",
            message: !formData.message.trim() ? "Message is required" : "",
        };

        setValidationErrors(newValidationErrors);
        if (
            newValidationErrors.name &&
            newValidationErrors.email &&
            newValidationErrors.subject &&
            newValidationErrors.message
        ) {
            setIsLoading(false);
            return;
        }
        try {
            const response = await axios(`${config.domain}submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: formData,
            });
            console.log(response);
            if (response.status === 200) {
                setSuccessMessage("Message sent successfully!");
                setErrorMessage("");
                console.log('Email sent successfully');
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage("Error sending message. Please try again.");
            setSuccessMessage("");
        }
        setIsLoading(false);
    };
    const fieldValidations = (field, value) => {

        if (field === "name") {
            if (!value.trim()) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    name: "Name is required",
                }));
            } else {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    name: "",
                }));
            }
        } else if (field === "email") {
            if (!value.trim()) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "Email is required",
                }));
            } else if (!isValidEmail(value)) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "Invalid email format",
                }));
            } else {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    email: "",
                }));
            }
        } else if (field === "subject") {
            if (!value.trim()) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    subject: "Subject is required",
                }));
            } else {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    subject: "",
                }));
            }
        } else if (field === "message") {
            if (!value.trim()) {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    message: "Message is required",
                }));
            } else {
                setValidationErrors((prevErrors) => ({
                    ...prevErrors,
                    message: "",
                }));
            }
        }
    }
    const handleFormChange = (field, value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
        fieldValidations(field, value)
    };


    return (
        //TODO Edit the information and add Sahelian Dev contact
        <section id="contact" className="contact theSection" ref={reference}>
            <div className="container">
                <div className="section-title">
                    <h2>Contact</h2>
                    <h3>
                        Nous <span>Contactez</span>
                    </h3>
                    <p>
                        N'hésitez pas à nous contacter pour discuter de votre projet. Vous pouvez nous joindre par téléphone ou par e-mail.
                        Nous serions ravis de répondre à toutes vos questions et de discuter de vos idées.
                    </p>
                </div>
                <div>
                    <iframe
                        title="Localisation"
                        style={{ border: 0, width: "100%", height: 270 }}
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3883.873646433749!2d2.273675075766322!3d13.233245209086572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTPCsDEzJzU5LjciTiAywrAxNiczNC41IkU!5e0!3m2!1sfr!2sne!4v1688812196439!5m2!1sfr!2sne"
                        allowFullScreen=""
                    />
                </div>
                <div className="row mt-5">
                    <div className="col-lg-4">
                        <div className="info">
                            <div className="address">
                                <i className="bi bi-geo-alt" />
                                <h4>Notre position:</h4>
                                <p>Niamey - Niger</p>
                            </div>
                            <div className="email">
                                <i className="bi bi-envelope" />
                                <h4>Email:</h4>
                                <p>saheliandev7@gmail.com</p>
                            </div>
                            <div className="phone">
                                <i className="bi bi-phone" />
                                <h4>Telephone:</h4>
                                <p>+227 88 10 30 73 </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mt-5 mt-lg-0">
                        <form
                            className="php-email-form"
                            onSubmit={(event) => handleSubmit(event)}
                        >
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        className="form-control"
                                        id="name"
                                        placeholder="Votre nom"

                                        value={formData.name}
                                        onChange={(e) =>
                                            handleFormChange("name", e.target.value)
                                        }
                                    />
                                    {validationErrors.name && (
                                        <div className="error-message">{validationErrors.name}</div>
                                    )}
                                </div>
                                <div className="col-md-6 form-group mt-3 mt-md-0">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Votre Email"

                                        value={formData.email}
                                        onChange={(e) =>
                                            handleFormChange("email", e.target.value)
                                        }
                                    />
                                    {validationErrors.email && (
                                        <div className="error-message">{validationErrors.email}</div>
                                    )}
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="subject"
                                    id="subject"
                                    placeholder="Le sujet de votre choix pour l'équipe sahelian Dev "

                                    value={formData.subject}
                                    onChange={(e) =>
                                        handleFormChange("subject", e.target.value)
                                    }
                                />
                                {validationErrors.subject && (
                                    <div className="error-message">{validationErrors.subject}</div>
                                )}
                            </div>
                            <div className="form-group mt-3">
                                <textarea
                                    className="form-control"
                                    name="message"
                                    rows={5}
                                    placeholder="Message"

                                    value={formData.message}
                                    onChange={(e) =>
                                        handleFormChange("message", e.target.value)
                                    }
                                />
                                {validationErrors.message && (
                                    <div className="error-message">{validationErrors.message}</div>
                                )}
                            </div>
                            <div className="my-3">
                                {isLoading && <div className="loading">Loading...</div>}
                                {errorMessage && <div className="error-message">{errorMessage}</div>}
                                {successMessage && <div className="success-message">{successMessage}</div>}
                            </div>
                            <div className="text-center">
                                <button type="submit" disabled={isLoading} className={isLoading ? "bg-transparent text-light" : ""}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact
