import React from 'react'
import { FaCheckDouble } from "react-icons/fa";

const About = ({ reference }) => {
  return (
    <section id="about" className="about theSection" ref={reference}>
      <div className="container">
        <div className="section-title">
          <h2>A propos</h2>
          <h3>
            Lire plus <span>Concernant Sahelian Dev</span>
          </h3>
          <p>
            Notre entreprise de développement se spécialise dans la création de solutions sur mesure pour répondre aux besoins de nos clients.
            .
          </p>
        </div>
        <div className="row content">
          <div className="col-lg-6">
            <ul>
              <li>
                <FaCheckDouble className="iconService" />Nous offrons des services de développement d'applications web, mobile et desktop pour les entreprises de toutes tailles allant du petit site vitrine au site e-commerce complexe.
              </li>
              <li>
                <FaCheckDouble className="iconService" />Nous sommes également experts en développement d'applications pour iOS et Android, offrant une expérience utilisateur optimale pour les smartphones et les tablettes.
              </li>

              <li>

                <FaCheckDouble className="iconService" />Nous travaillons dans un environnement agile pour assurer une flexibilité maximale et une livraison rapide de produits de haute qualité.
              </li>
            </ul>
          </div>
          <div className="col-lg-6 pt-4 pt-lg-0">
            <ul>
              <li>
                <FaCheckDouble className="iconService" /><strong>Nos objectifs sont :</strong><br></br>
              </li>
              <li>
                <FaCheckDouble className="iconService" />Développement de solutions innovantes et utiles<br></br>
              </li>
              <li>
                <FaCheckDouble className="iconService" />Inclusion numérique du pays<br></br>
              </li>
              <li>
                <FaCheckDouble className="iconService" />Promouvoir la sécurité informatique
              </li>
              <li>
                <FaCheckDouble className="iconService" />Promouvoir l'innovation de la tech au sahel
              </li>
            </ul>
            <a href="#contact" className="btn-learn-more">
              Plus d'infos
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
