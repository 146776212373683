import React from 'react'
import 'bootstrap-icons/font/bootstrap-icons.css';
import { FaFingerprint } from "react-icons/fa";
import { FaNetworkWired } from "react-icons/fa";
import { FaTools } from "react-icons/fa";
import { FaLightbulb } from "react-icons/fa";

const Services = ({ reference }) => {
    return (
        //TODO Add services and description
        <section id="services" className="services theSection" ref={reference}>
            <div className="container">
                <div className="section-title">
                    <h2>Explorer Nos Services</h2>
                    <h3>
                        Nous offrons des <span>Services</span>
                    </h3>
                </div>
                <div className="row">
                    <div className="col-md-6 col-lg-3 col-12 d-flex align-items-scretch mb-5 mb-lg-0">
                        <div className="icon-box col-12">
                            <div className="icon">
                                <FaLightbulb className="iconService creation" />
                            </div>
                            <h4 className="title">
                                Création
                            </h4>
                            <div className="description">
                                <ul>
                                    <li>Site, Applications Web et mobile</li>
                                    <li>Site E-commerce</li>
                                    <li>Plateforme E-learning</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-scretch mb-5 mb-lg-0">
                        <div className="icon-box col-12">
                            <div className="icon">
                                <FaTools className="iconService informatique" />
                            </div>
                            <h4 className="title">
                                Maintenance informatique
                            </h4>
                            <div className="description">
                                <ul>
                                    <li>Installation système</li>
                                    <li>Maintenance et entretien des équipements</li>
                                    <li>Installation et vente des anti-virus</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-scretch mb-5 mb-lg-0">
                        <div className="icon-box col-12">
                            <div className="icon">
                                <FaFingerprint className='iconService cyber' />
                            </div>
                            <h4 className="title">
                                Cyber sécurité
                            </h4>
                            <div className="description">
                                <ul>
                                    <li>Sensibilisation à l'hygiène informatique</li>
                                    <li>Teste de penetration</li>
                                    <li>Privacy Ops</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 d-flex align-items-scretch mb-5 mb-lg-0">
                        <div className="icon-box col-12">
                            <div className="icon">
                                <FaNetworkWired className='iconService reseau' />
                            </div>
                            <h4 className="title">
                                Réseau informatique
                            </h4>
                            <div className="description">
                                <ul>
                                    <li>Conception réseau local</li>
                                    <li>Installation peripherique intermèdiare</li>
                                    <li>Sécurité resèau</li>
                                    <li>Vente des materiels</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Services
