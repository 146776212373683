import React, { useState } from "react";
import { Offcanvas, CloseButton } from "react-bootstrap";
import logo from "../../img/logo-no-background.png";

const links = [
  {
    link: "welcome",
    text: "Accueil",
  },
  {
    link: "about",
    text: "A propos de nous",
  },
  {
    link: "services",
    text: "Nos Services",
  },
  {
    link: "portfolio",
    text: "Nos projets",
  },
  {
    link: "team",
    text: "Notre Team",
  },
  {
    link: "contact",
    text: "Contactez-nous",
  },
];

const Header = ({ scroll }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <header id="header" className="fixed-top bg-dark">
      <div className="container d-flex align-items-center justify-content-between">
        <h1 className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </h1>
        <nav id="navbar" className="navbar">
          <ul>
            {links.map((link) => (
              <li key={link.link}>
                <a
                  className={`nav-link scrollto ${link.link === scroll ? "active" : ""
                    }`}
                  href={`#${link.link === "welcome" ? "" : link.link}`}
                >
                  {link.text}
                </a>
              </li>
            ))}
          </ul>
          <i onClick={handleShow} className="bi bi-list mobile-nav-toggle" />
        </nav>
      </div>

      <Offcanvas
        className="bg-dark"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Header>
          <Offcanvas.Title className="w-100">
            <CloseButton
              style={{ backgroundColor: "#fff" }}
              onClick={handleClose}
            />
            <div className="m-auto w-auto d-flex justify-content-center">
              <img src={logo} alt="logo" className="logo" style={{ width: 200 }} />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            className="w-100 h-100 d-flex flex-row justify-content-center align-items-center navbar"
            style={{}}
          >
            <ul
              className="d-flex flex-column p-0"
              style={{ listStyleType: "none" }}
            >
              {links.map((link) => (
                <li
                  style={{ fontSize: 20, marginBottom: 15, fontFamily: "" }}
                  key={link.link}
                >
                  <a
                    onClick={handleClose}
                    className={`nav-link scrollto ${link.link === scroll ? "active" : ""
                      }`}
                    href={`#${link.link === "welcome" ? "" : link.link}`}
                  >
                    {link.text}
                  </a>
                </li>
              ))}
            </ul>
            <div className="offcanva-footer ">
              <hr className="separator" />
              <div className="me-md-auto text-center text-md-start">
                <div className="copyright">
                  © Copyright{" "}
                  <strong>
                    <span>Sahelian Dev</span>
                  </strong>
                  . All Rights Reserved
                </div>
                <div className="credits">
                  Designed by <span>Sahelian Dev</span>
                </div>
              </div>
            </div>
          </div>
          {/* <i onClick={handleShow} className="bi bi-list mobile-nav-toggle" /> */}
        </Offcanvas.Body>
      </Offcanvas>
    </header>
  );
};

export default Header;
