import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img1 from "../../img/team/team-1.jpg";
import img2 from "../../img/team/team-2.jpg";
import img3 from "../../img/team/team-3.jpg";
import img4 from "../../img/team/team-4.jpg";
import img44 from "../../img/team/team-44.jpg";
import SocialsTeam from "./../ui/SocialsTeam";
const Team = ({ reference }) => {
  const members = [
    {
      name: "Alio Mahaman Sani",
      position: "Chief Executive Officer",
      image: img1,
      links: [
        { icon: "bi bi-github", link: "https://github.com/CS-M-sani" },
        {
          icon: "bi bi-linkedin",
          link: "https://www.linkedin.com/in/m-sani-amadou-3a865b23a/",
        },
        {
          icon: "bi bi-facebook",
          link: "https://www.facebook.com/msani.amadou.37",
        },
      ],
    },
    {
      name: "Mahamadou Nouridine",
      position: "Product Manager",
      image: img2,
      links: [
        {
          icon: "bi bi-github",
          link: "https://github.com/mahamadou-nouridine",
        },
        {
          icon: "bi bi-linkedin",
          link: "https://www.linkedin.com/in/mahamadou-nouridine/",
        },
        {
          icon: "bi bi-facebook",
          link: "https://www.facebook.com/Nouridine2k/",
        },
        {
          icon: "angellist",
          link: "https://wellfound.com/u/mahamadou-nouridine",
        },
      ],
    },
    {
      name: "Abdoul Kassoum",
      position: "CTO",
      image: img3,
      links: [
        { icon: "bi bi-github", link: "https://github.com/abdoulkassoum" },
        {
          icon: "bi bi-linkedin",
          link: "https://www.linkedin.com/in/abdoulkassoum-djibril-566362124/",
        },
        {
          icon: "bi bi-facebook",
          link: "https://www.facebook.com/ganodorf.goron",
        },
      ],
    },
    {
      name: "Yacoubou Seidou",
      position: "Project Manager/ Marketing",
      image: img4,
      links: [
        { icon: "bi bi-github", link: "https://github.com/Yacoubou-seidou" },
        {
          icon: "bi bi-linkedin",
          link: "https://www.linkedin.com/in/yacoubou-seidou-chaibou/",
        },
        {
          icon: "bi bi-facebook",
          link: "https://www.facebook.com/Blackyacos.chaibou",
        },
        {
          icon: "angellist",
          link: "https://wellfound.com/u/seidou-yacoubou",
        },
      ],
    },
    {
      name: 'Beelal',
      position: 'Accountant',
      image: img44,
      links: [
        { icon: "bi bi-github", link: "https://github.com/Beellal" },
        {
          icon: "bi bi-linkedin",
          link: "https://www.linkedin.com/in/mahamane-habibou-bilal-364771217/",
        },
        {
          icon: "bi bi-facebook",
          link: "https://www.facebook.com/profile.php?id=100017645508804",
        }
      ],
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section id="team" className="team theSection" ref={reference}>
      <div className="container">
        <div className="section-title">
          <h2>L'équipe</h2>
          <h3>
            <span>Sahelian Dev</span>
          </h3>
          <p>
            C'est une équipe dynamique, talentueuse et multidisciplinaire
            spécialisée dans le développement de solutions innovantes. Nous
            sommes composés des développeurs Full-Stack hautement qualifiés et
            experts dans divers langages de programmation Frontend et Backend,
            des experts en Design et des spécialistes en cyber sécurité{" "}
          </p>
        </div>
        <div className="team-slider row">
          <Slider {...settings}>
            {members.map((member) => (
              <div
                key={member.name}
                className="cardTeam col-lg-3 col-md-6 d-flex align-items-stretch mb-5 mb-lg-0"
              >
                <div className="member mx-2">
                  <div className="member-img">
                    <img src={member.image} className="img-fluid" alt="" />
                    <SocialsTeam array={member.links} />
                  </div>
                  <div className="member-info">
                    <h4>{member.name}</h4>
                    <span>{member.position}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Team;
