import React from "react";
import { FaAngellist } from "react-icons/fa";

const SocialsTeam = ({ array }) => {
  return (
    <div className="social">
      {array.map((obj, index) => {
        return (
          <a href={obj.link} key={obj.link + index}>
            {obj.icon === "angellist" ? (
              <FaAngellist />
            ) : (
              <i className={obj.icon} />
            )}
          </a>
        );
      })}
    </div>
  );
};

export default SocialsTeam;
