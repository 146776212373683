import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const url = 'https://';

export const blogData = createAsyncThunk('blog', async () => {
  const response = await fetch(url);
  const result = await response.json();

  const data = result.map((item) => ({
    name: item.name,
  }));

  return data;
});

const initialState = {
  blogData: [],
  loading: false,
  error: null,
};

const blogSlice = createSlice({
  name: 'country',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(blogData.pending, (state) => ({
        ...state,
        loading: true,
      }))
      .addCase(blogData.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        blogData: action.payload,
      }))
      .addCase(blogData.rejected, (state) => ({
        ...state,
        loading: true,
      }));
  },
});

export default blogSlice.reducer;
