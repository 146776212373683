import React from "react";
import img1 from "../../img/portfolio/portfolio-1.jpg";
import img2 from "../../img/portfolio/portfolio-2.jpg";
import img3 from "../../img/portfolio/portfolio-3.jpg";
import img4 from "../../img/portfolio/portfolio-4.jpg";
import img5 from "../../img/portfolio/portfolio-5.jpg";
import img6 from "../../img/portfolio/portfolio-6.jpg";
import img7 from "../../img/portfolio/portfolio-7.jpg";
import img8 from "../../img/portfolio/portfolio-8.jpg";
import img9 from "../../img/portfolio/portfolio-9.jpg";

const PortofolioCard = ({ reference }) => {
  return (
    <section id="portfolio" className="portfolio theSection" ref={reference}>
      <div className="container">
        <div className="section-title">
          <h2>Portfolio</h2>
          <h3>
            Explorer notre <span>Portfolio</span>
          </h3>
          <p>Les projets réalisés par Sahelian Dev</p>
        </div>
        {/* <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" className="filter-active">
                Nos projets
              </li>
              <li data-filter=".filter-app">Sites Web</li>
              <li data-filter=".filter-card">Applications</li>
              <li data-filter=".filter-web">Plateformes</li>
            </ul>
          </div>
        </div> */}
        <div className="row portfolio-container">
          {[...Array(20).keys()].map((el) => {
            const img = require(`../../img/projects/project${el}.png`);
            return (
              <div
                key={el}
                className="col-lg-4 col-md-6 portfolio-item filter-app"
              >
                <img src={img} className="img-fluid" alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PortofolioCard;
