import React from 'react'
import { FaGlobe } from "react-icons/fa";
import { FaSellsy } from "react-icons/fa";
import { FaSchool } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { FaToolbox } from "react-icons/fa";
import { FaShieldAlt } from "react-icons/fa";
import { FaDiscourse } from "react-icons/fa";
import { FaNotesMedical } from "react-icons/fa";
import { FaDoorClosed } from "react-icons/fa";
import { FaNetworkWired } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { FaDesktop } from "react-icons/fa";

const Features = () => {
  return (
    <section id="features" className="features theSection">
      <div className="container">
        <div className="row d-flex ">
          <div className="col-lg-3 col-md-6 col-12 mb-2">
            <div className="mb-2">
              <div className="icon-box">
                <FaGlobe className="iconService" style={{ color: "#5578ff" }} />
                <h3>
                  <p>Site Web</p>
                </h3>
              </div>
            </div>
            <div className="mb-2">
              <div className="icon-box">
                <FaSellsy className="iconService" style={{ color: "#5578ff" }} />
                <h3>
                  <p>Site E-commerce</p>
                </h3>
              </div>
            </div>
            <div className="mb-2">
              <div className="icon-box">
                <FaSchool className="iconService" style={{ color: "#5578ff" }} />
                <h3>
                  <p>Plateforme E-learning</p>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mb-2">
            <div className="mb-2">
              <div className="icon-box">
                <FaDownload className="iconService" style={{ color: "#e80368" }} />
                <h3>
                  <p>Installation système</p>
                </h3>
              </div>
            </div>
            <div className="mb-2">
              <div className="icon-box">
                <FaToolbox className="iconService" style={{ color: "#e80368" }} />
                <h3>
                  <p>Maintenance et entretien</p>
                </h3>
              </div>
            </div>
            <div className="mb-2">
              <div className="icon-box">
                <FaShieldAlt className="iconService" style={{ color: "#e80368" }} />
                <h3>
                  <p>vente anti-virus</p>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mb-2">
            <div className="mb-2">
              <div className="icon-box">
                <FaDiscourse className="iconService" style={{ color: "#11dbcf" }} />
                <h3>
                  <p>Sensibilisation</p>
                </h3>
              </div>
            </div>
            <div className="mb-2">
              <div className="icon-box">
                <FaNotesMedical className="iconService" style={{ color: "#11dbcf" }} />
                <h3>
                  <p>Teste de penetration</p>
                </h3>
              </div>
            </div>

            <div className="mb-2">
              <div className="icon-box">
                <FaDoorClosed className="iconService" style={{ color: "#11dbcf" }} />
                <h3>
                  <p>Privacy Ops</p>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-12 mb-2">
            <div className="mb-2">
              <div className="icon-box">
                <FaNetworkWired className="iconService" style={{ color: "#4233ff" }} />
                <h3>
                  <p>Conception réseau local</p>
                </h3>
              </div>
            </div>
            <div className="mb-2">
              <div className="icon-box">
                <FaLock className="iconService" style={{ color: "#4233ff" }} />
                <h3>
                  <p>Sécurité resèau</p>
                </h3>
              </div>
            </div>
            <div className="mb-2">
              <div className="icon-box">
                <FaDesktop className="iconService" style={{ color: "#4233ff" }} />
                <h3>
                  <p>Vente des materiels</p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default Features
