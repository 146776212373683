import React from 'react'

const Hero = ({ reference }) => {
    return (
        <section id="hero" className='theSection' ref={reference}>
            <div className="hero-container">
                <h3>
                    <strong>Bienvenue sur</strong>
                </h3>
                <h1>SAHELIAN DEV</h1>
                <h2>Nous sommes une équipe de talent avec un savoir faire dynamique</h2>
                <a href="#about" className="btn-get-started scrollto">
                    EXPLORER
                </a>
            </div>
        </section>
    )
}

export default Hero
