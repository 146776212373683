import React from 'react'

const Footer = () => {
    return (
        <footer id="footer">
            <div className="container d-md-flex py-4">
                <div className="me-md-auto text-center text-md-start">
                    <div className="copyright">
                        © Copyright{" "}
                        <strong>
                            <span>Sahelian Dev</span>
                        </strong>
                        . Tous les droits reservés
                    </div>
                    <div className="credits">
                        site Conçu par <a href="/">Sahelian Dev</a>
                    </div>
                </div>
                <div className="social-links text-center text-md-right pt-3 pt-md-0">
                    <a href="/#" className="twitter">
                        <i className="bi bi-twitter" />
                    </a>
                    <a href="https://www.facebook.com/SahelianDev" className="facebook">
                        <i className="bi bi-facebook" />
                    </a>
                    <a href="https://github.com/Sahelian-Dev" className="github">
                        <i className="bi bi-github" />
                    </a>
                    <a href="https://www.linkedin.com/company/sahelian-dev/" className="linkedin">
                        <i className="bi bi-linkedin" />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
